@charset "UTF-8";
/* You can add global styles to this file, and also import other style files */
html, body {
  margin: 0;
  padding: 0;
  background-color: #f1f2f6 !important;
  height: 100%;
  font-family: Arial, Helvetica, sans-serif;
}

.menu-content.h-100 {
  background-color: #f1f2f6;
}

li:hover {
  cursor: pointer;
}

a:hover {
  cursor: pointer;
}

.swal2-confirm {
  width: 103px;
}

.sticky-div {
  z-index: 998;
  position: sticky;
  top: 60px;
  background: #f5f5f9;
  border-radius: 3px;
  margin-bottom: 9px;
  padding-left: 10px;
}

.swal2-styled {
  width: 100px;
}

.form-style {
  margin-left: 250px;
}

/* CAN (MUST) BE REMOVED ! Sample Global style */
.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: url(./assets/background.jpg) no-repeat center fixed;
  -webkit-background-size: cover;
  /* pour anciens Chrome et Safari */
  background-size: cover;
  /* version standardisée */
}
.container .title {
  color: white;
  margin: 0;
  padding: 50px 20px;
}
.container a {
  color: #fff !important;
  text-transform: uppercase;
  text-decoration: none;
  background: #ed3330;
  padding: 20px;
  border-radius: 5px;
  display: inline-block;
  border: none;
  transition: all 0.4s ease 0s;
}
.container a:hover {
  background: #fff;
  color: #ed3330 !important;
  letter-spacing: 1px;
  -webkit-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
  -moz-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
  box-shadow: 5px 40px -10px rgba(0, 0, 0, 0.57);
  transition: all 0.4s ease 0s;
}

.btn {
  margin: 5px;
}

.add-button-class {
  margin-bottom: 9px;
  margin-left: 402px;
  margin-top: -33px;
}

.button-items .line {
  font-size: 30px;
  padding: 0;
}
.button-items button {
  border: none;
}
.button-items i {
  font-size: 2rem !important;
  font-weight: lighter;
}
.button-items .btn:disabled {
  border-color: grey;
  color: grey;
}

.page-title {
  font-weight: normal;
  text-transform: uppercase;
  font-size: 20px;
}

.page-title-box {
  padding: 5px 0 !important;
}

.wrning-tag {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #ef4d56;
}

.preview-data .preview-div {
  margin-left: 11px;
}
.preview-data .table {
  padding: 0.4rem 0.4em;
}
.preview-data .info-section {
  padding-left: 10px;
}
.preview-data .title {
  font-weight: bold !important;
  color: grey !important;
  font-size: 10pt !important;
}
.preview-data div, .preview-data p {
  font-size: 10pt;
  font-weight: 300;
}
.preview-data .card {
  margin-bottom: 5px;
}
.preview-data .card-header {
  padding-bottom: 0;
  padding-top: 0;
}
.preview-data td {
  font-size: 10pt !important;
}
.preview-data span.bold {
  font-size: 10pt;
  font-weight: lighter;
  text-transform: uppercase;
}
.preview-data .preview-title {
  color: grey;
  padding: 0;
  margin: 0;
  font-size: 1rem;
  font-weight: 400;
  text-transform: uppercase;
}
.preview-data .preview-title-examinations {
  margin-bottom: -2px;
}
.preview-data tr.ng-star-inserted {
  font-weight: 500 !important;
  font-size: 8pt;
}

.search-btn {
  margin-top: -18%;
}

.search-btn-appointment {
  padding: 13%;
  margin-top: -18%;
}

.scroll {
  height: calc(100vh - 80px);
  overflow-y: auto;
}

.autocomplete-container {
  width: 70% !important;
}

.cdk-overlay-container {
  left: 10%;
  transform: translateX(-7%);
  width: 49%;
  z-index: 1001 !important;
}

.mat-dialog-container {
  width: 80% !important;
  margin: auto;
}

.control-button .btn {
  border: none;
}
.control-button .btn i {
  font-size: 1.5rem;
}
.control-button .btn .main {
  color: whitesmoke;
}

div#cdk-overlay-1, div#cdk-overlay-3,
div#cdk-overlay-5, div#cdk-overlay-7,
div#cdk-overlay-9, div#cdk-overlay-11 {
  left: 12.5% !important;
}

div#cdk-overlay-2, div#cdk-overlay-4,
div#cdk-overlay-8, div#cdk-overlay-6,
div#cdk-overlay-10, div#cdk-overlay-12 {
  left: 50.5% !important;
}

.cal-events.ng-star-inserted {
  display: none !important;
}